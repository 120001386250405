.MuiTablePagination-spacer {
    display: none !important;
}
.MuiMenu-list {
    color: #ffffff !important;
}

.MuiPaginationItem-root {
    color: #ffffff !important;
}

.MuiSelect-select.MuiSelect-select {
    border: 1px solid;
}
.MuiSelect-icon {
    color: #ffffff !important;
}

.MuiFlatPageButton-root {
    color: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-color: black !important;
    height: 32px !important;
    margin: 0 3px !important;
    padding: 0 6px !important;
    font-size: 0.875rem !important;
    min-width: 32px !important;
    font-weight: 400 !important;
    line-height: 1.43 !important;
    border-radius: 16px !important;
}

.MuiButton-root.Mui-disabled {
    color: gray !important;
}

.MuiFlatPageButton-rootStandard {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-color: black !important;
    height: 32px !important;
    margin: 0 3px !important;
    padding: 0 6px !important;
    font-size: 0.875rem !important;
    min-width: 32px !important;
    font-weight: 400 !important;
    line-height: 1.43 !important;
    border-radius: 16px !important;
}

.MuiButton-textSecondary {
    background-color: #464659 !important;
}
.MuiTablePagination-caption {
    display: none;
}
.MuiTablePagination-actions {
    display: none;
}