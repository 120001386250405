.MuiAccordionSummary-expandIcon {
  transform: rotate(-90deg) !important;
  position: absolute !important;
  left: 0px !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg) !important;
}

